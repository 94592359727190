export interface Language {
    name: string;
    label: string;
}

export const languages: Language[] = [
    { name: 'nl', label: 'Nederlands' },
    { name: 'en', label: 'Engels' },
    { name: 'de', label: 'Duits' },
    { name: 'ms', label: 'Maleis' },
    { name: 'fi', label: 'Fins' },
    { name: 'id', label: 'Indonesisch' },
    { name: 'ar', label: 'Arabisch' },
    { name: 'it', label: 'Italiaans' },
    { name: 'zh', label: 'Chinees' },
    { name: 'fr', label: 'Frans' },
    { name: 'es', label: 'Spaans' },
    { name: 'el', label: 'Grieks' },
    { name: 'pl', label: 'Pools' },
    { name: 'hr', label: 'Kroatisch' },
];

export const languageOptions = languages.map((language) => ({
    value: language.name,
    label: language.label,
}));
